import React from 'react'

const MainTinyCentered: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>
> = ({ children, className, ...other }) => {
  return (
    <main
      className={
        'md:mx-auto md:max-w-[450px]' + (className ? ` ${className}` : '')
      }
      {...other}
    >
      {children}
    </main>
  )
}

export default MainTinyCentered
